// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compose-js": () => import("./../src/pages/compose.js" /* webpackChunkName: "component---src-pages-compose-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messages-js": () => import("./../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */)
}

